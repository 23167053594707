.login-screen {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: gray;
  height: 100vh;
  position: relative;

  .login-form {
    display: flex;
    background: white;
    width: 30%;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow: hidden;
  }
}
