.screen {
  .wrapper {
    height: 100vh;
    .ant-layout-content {
      max-height: 80%;
      overflow: auto;
    }
  }
  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .logo {
    height: 70px;
    margin: 16px;
  }

  .site-layout-background {
    background: #fff;
  }
}
